<template>
  <span>
    <v-row dense>
      <v-col cols="3" class="mr-0 pr-0">
        <v-autocomplete
	        outlined
	        :items="getCountries"
	        item-text="alpha2Code"
	        :item-value="country"
	        auto-select-first
	        v-model="country"
	        @change="
            $refs['phone-input'].focus();
            $emit('input', prefix + phone_number);
          "
        >
          <template v-slot:prepend-inner>
            <v-img
	            width="30px"
	            :src="selectedCountry ? selectedCountry.flag : ''"
            ></v-img>
          </template>

          <template v-slot:item="{ item }">
            <flag class="mr-2" :iso="item.alpha2Code"></flag>
            {{ item.alpha2Code }}({{ item.callingCodes[0] }})
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="9" class="ml-0 pl-0">
        <v-text-field
	        :label="hideLabel ? '' : 'Phone Number'"
	        v-model="phone_number"
	        @input="$emit('input', prefix + phone_number)"
	        ref="phone-input"
	        :prefix="prefix"
	        outlined
	        type="tel"
	        :rules="phone_number_rules"
	        persistent-hint
	        hint="Enter phone number excluding the leading 0"
        ></v-text-field>
      </v-col>
    </v-row>
  </span>
</template>

<script>
import countries from "../utils/countries.json";

export default {
	name: "PhoneNumberInput",
	props: ["default_number", "isEmailLogin","hideLabel"],
	emits: ["input"],
	data() {
		return {
			countries: [],
			country: "GH",
			phone_number: "",
			phone_number_rules: [(value) => !!value || "Phone number is Required."],
		};
	},
	watch: {
		default_number() {
			this.phone_number = this.default_number;
			this.$emit("input", this.prefix + this.phone_number);
		},
	},
	computed: {
		selectedCountry() {
			return this.getCountries.find((country) => {
				return country.alpha2Code === this.country;
			});
		},
		getCountries() {
			return countries;
		},
		prefix() {
			const p = this.selectedCountry
				? this.selectedCountry.callingCodes[0]
				: "";
			return p ? "+" + p : "";
		},
	},
	mounted() {

		if (this.default_number) {
			this.phone_number = this.default_number;

			this.$emit("input", this.prefix + this.phone_number);
		}
		// axios.get("http://ip-api.com/json/").then((res) => {
		// 	if (res.data.status === "success") {
		// 		this.country = res.data.countryCode;
		// 	}
		// });
	},
};
</script>

<style scoped>
</style>