import VueRouter from "vue-router";
import Vue from "vue";
import loginComponent from "./components/LoginComponent.vue";
import signupComponent from "./components/SignupComponent.vue";

Vue.use(VueRouter);
const routes = [
	{
		path: "/login",
		redirect: "/auth/login",
	},
	{
		path: "/auth",
		component: () => import("./components/auth.vue"),
		children: [
			{
				path: "",
				component: () => import("./components/LoginComponent.vue"),
				name: "Auth"
			},
			{
				path: "login",
				component: loginComponent,
				name: "Login",
				meta: {
					title: "Login"
				}
			},
			{
				path: "signup",
				component: signupComponent,
				name: "Signup",
				meta: {
					title: "Signup"
				}
			},
		],
	},
	{
		path: "/dashboard",
		component: () => import("./components/home.vue"),
		meta: {
			requiresAuth: true, // Set this route to require authentication
			title: "Dashboard"
		},
	},
	{
		path: "/",
		component: () => import("./components/home.vue"),
		meta: {
			title: "Dashboard"
		},
	},
	{
		path: "/reports/:id",
		component: () => import("./components/mgmt-report/ReportTemplate.vue"),
		meta: {
			requiresAuth: true, // Set this route to require authentication
			title: "Report"
		},
	},
	{
		path: "/profile",
		component: () => import("./components/Profile.vue"),
		meta: {
			requiresAuth: true, // Set this route to require authentication
			title: "Profile"
		},
	},
];
const router = new VueRouter({
	routes,
	mode: "history",
});

router.beforeEach((to, from, next) => {
	document.title = 'CFO AI By Built | ' + to.meta.title
	next();
})

export default router;
