<template>
<span>

  <v-menu
	  transition="slide-y-transition"
	  max-width="300"
	  offset-y
  >
    <template v-slot:activator="{on}">

  <v-btn id="CFO_PROFILE_MENU" text rounded v-on="on" x-large>
    <avatar-component v-if="!user.photo" size="35" :label="label"></avatar-component>
    <v-avatar v-else size="40">
      <v-img :src="user.photo"></v-img>
    </v-avatar>
    <span class="trim-text-no-padding">{{
		    user.first_name
	    }} {{ user.last_name }}</span> <v-icon
	 >mdi-chevron-down</v-icon>
  </v-btn>
    </template>
<v-list>

  <v-list-item>
    <v-list-item-avatar>
        <avatar-component size="45" v-if="!user.photo" :label="label"></avatar-component>
      <v-avatar v-else size="40">
      <v-img :src="user.photo"></v-img>
    </v-avatar>
    </v-list-item-avatar>
    <v-list-item-content>

    <v-list-item-title class="trim-text-no-padding">{{ user.first_name }} {{ user.last_name }}</v-list-item-title>
    <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>

    </v-list-item-content>
  </v-list-item>

  <v-list-item to="/profile">
    <v-list-item-content>
    <v-list-item-title>Profile Settings</v-list-item-title>
    </v-list-item-content>
<v-list-item-action>
  <v-icon small color="grey">mdi-chevron-right</v-icon>
</v-list-item-action>
  </v-list-item>

    <v-list-item @click="eventBus.$emit('logout')">
    <v-list-item-title class="red--text">Logout</v-list-item-title>
  </v-list-item>

</v-list>
  </v-menu>

</span>

</template>

<script>
import AvatarComponent from "@/components/AvatarComponent.vue";
import eventBus from "@/utils";

export default {
	name: "ProfileMenu",
	components: {AvatarComponent},
	computed: {
		eventBus() {
			return eventBus
		},
		label() {
			return this.user.first_name + " " + this.user.last_name;
		},
		user() {
			return this.$store.state.user;
		}
	},
}
</script>

<style scoped>

</style>