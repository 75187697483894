<template>
	<v-container v-if="show_notification || reportsGenerating.length>0" class="pa-3 mt-6 mb-5"
	>
		<!-- START Box -->
		<div v-if="show_notification" class="animated-border-box-glow"></div>

		<v-row
			align="center"
			align-content="center"
			v-if="show_notification"
			class="animated-border-box"
			:style="`background-color:${alertColor}`"
		>

			<v-col cols="12" sm="">
				<lottie-animation
					ref="waitAnim"
					:animationData="require('@/assets/animations/waiting2.json')"
					:autoPlay="true"
					:loop="true"
				/>

			</v-col>
			<v-col cols="12" sm="10">
				<h3 class="font-weight-light text-h5 white--text">{{ report_notification }}</h3>
			</v-col>
		</v-row>

		<div v-if="!reportsGenerating.length" style="margin-top: 160px !important">

		</div>
		<div v-if="reportsGenerating.length > 0" :style="show_notification ? 'margin-top: 160px !important' : ''">

			<v-alert :color="i.status !== 'done' ? 'light-blue darken-4' : 'teal darken-4'"
			         dense dark
			         elevation="1"
			         v-for="(i, index) in reportsGenerating" :key="i.report_id"
			         :dismissible="i.status === 'report_generating_completed'"

			         close-icon="mdi-close"
			         shaped>
				<template v-slot:close>
					<v-btn icon @click="reportsGenerating.splice(index,1)">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</template>
				<v-row align="center">

					<v-col cols="2">
						<lottie-animation
							ref="waitAnim"
							:animationData="i.status !=='report_generating_completed' ? require('@/assets/animations/wait.json') : require('@/assets/animations/success.json')"
							:autoPlay="true"
							:loop="false"
							style="height: 80px; display: inline-block"
						/>
					</v-col>
					<v-col cols="10">
						<v-card-title>
							<h3 class="font-weight-regular "><strong>{{ i.report.name }} </strong>{{
									i.status !== 'report_generating_completed' ? ' is being generated.' : ' has been successfully generated.'
								}}</h3>
							<v-spacer></v-spacer>
							<span v-if="i.status === 'report_generating_completed'">
								<v-btn @click="reportsGenerating.splice(index,1)" :to="`/reports/${i.report.uuid}`" depressed rounded
								       color="teal"
								       dark>View report<v-icon>mdi-open-in-new</v-icon>
								</v-btn>
							</span>
						</v-card-title>
					</v-col>
				</v-row>
			</v-alert>
		</div>
	</v-container>
</template>

<script>
import {defineComponent} from 'vue'
import LottieAnimation from "lottie-web-vue";
import eventBus from "@/utils";

export default defineComponent({
	name: "AppAlertsComponent",
	components: {LottieAnimation},
	data() {
		return {
			reportsGenerating: [],
			alertColor: "#06103b",
			show_notification: false,
			report_notification: "Initializing report generation, please wait..."
		}
	},

	methods: {
		getReports() {
			axios.get("/api/management-reports")
				.then(res => {
          this.$store.state.stats = res.data.stats;
					this.$store.state.reports = res.data.data;
				})
		},
	},
	mounted() {

		if (this.$store.state.managementProfile) {
			this.sockets.subscribe(`generating_report_${this.$store.state.managementProfile.id}`, (e) => {
				this.show_notification = true;
				this.report_notification = e.message;
			});

			this.sockets.subscribe(`report_generating_completed_${this.$store.state.managementProfile.id}`, (e) => {
				this.show_notification = false;
				this.reportsGenerating.push(e.data);
				eventBus.$emit('doneGenerating')
				this.getReports();

			});
		}

	}
})
</script>

<style scoped>
.animated-border-box {
	box-shadow: -1px 2px 44px -9px rgba(0, 0, 0, 0.65);
	-webkit-box-shadow: -1px 2px 44px -9px rgba(0, 0, 0, 0.65);
	-moz-box-shadow: -1px 2px 44px -9px rgba(0, 0, 0, 0.65);

}

.animated-border-box, .animated-border-box-glow {
	position: absolute;
	overflow: hidden;
	z-index: 0;
	height: 150px;
	width: 83%;
	top: 2%;
	/* Border Radius */
	border-radius: 15px !important;
}

.animated-border-box-glow {
	overflow: hidden;
	/* Glow Blur */
	filter: blur(20px);
}

.animated-border-box:before, .animated-border-box-glow:before {
	content: '';
	z-index: -2;
	text-align: center;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) rotate(0deg);
	position: absolute;
	width: 100%;
	height: 99999px;
	background-repeat: no-repeat;
	background-position: 0 0;
	background-image: conic-gradient(rgba(0, 0, 0, 0), #ADF0EE, rgba(0, 0, 0, 0) 25%);
	animation: rotate 4s linear infinite;
}

.animated-border-box:after {
	background-color: #06103b;

	content: '';
	position: absolute;
	z-index: -1;
	/* border width */
	left: 5px;
	top: 5px;
	/* double the px from the border width left */
	width: calc(100% - 10px);
	height: calc(100% - 10px);
	/*bg color*/
	/*box border radius*/
	border-radius: 15px !important;


}

@keyframes rotate {
	100% {
		transform: translate(-50%, -50%) rotate(1turn);
	}
}


</style>