<template>
  <div>
    <h4 class="text-center text-h5">Sign up</h4>
    <v-card-text class="">
      <v-stepper v-model="step" elevation="0" flat>
        <v-stepper-header style="border: none !important; box-shadow: none !important">
          <v-stepper-step :color="step > 1 ? 'green' : 'blue'" :complete="step > 1" step="1">Personal Info.
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :color="step > 2 ? 'green' : 'blue'" :complete="step > 2" step="2">Account Info.
          </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-form v-model="personal_infor" ref="person_form"
                    @keyup.native.enter="personal_infor && goto_account($event)">

                  <label>First Name*</label>
                  <v-text-field v-model="first_name" :rules="first_name_rules"
                                class="mt-2" outlined autofocus></v-text-field>

                  <label>Last Name*</label>

                  <v-text-field v-model="last_name" :rules="first_name_rules"
                                outlined></v-text-field>

              <label>Gender*</label>

                  <v-select
                      outlined
                      v-model="gender"
                      :items="items"
                      item-text="gender"
                      item-value="value"
                      :rules="$store.state.requiredRule"
                  ></v-select>

              <label>Phone Number*</label>

                  <phone-number-input :hide-label="true" @input="(ph) => (phone_number = ph)"
                                      :isEmailLogin="true"></phone-number-input>

            </v-form>

            <v-row>
              <v-col cols="12" sm="8" xs="12" offset-sm="4" class="mt-2">
                <v-spacer></v-spacer>
                <v-btn color="blue darken-4"
                       @click="goto_account"
                       block rounded
                       x-large depressed dark
                >
                  Continue
                  <v-icon>mdi-arrow-right</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-alert type="warning" text prominent v-if="exist">This email is already taken, if this is your email please
              <v-btn rounded to="/auth/login" small text color="orange"><v-icon small>mdi-arrow-left</v-icon>log in</v-btn>
            </v-alert>

            <v-form v-model="account_infor" ref="account_form"
                    @keyup.native.enter="account_infor && signup()">
              <label>Email*</label>

              <v-text-field :disabled="loading" type="email" class="mt-2" outlined :rules="email_rules"
                            v-model="email" @blur="check_exist()" :loading="checking" :autofocus="true"
                           >
              </v-text-field>

              <label>Password*</label>

              <v-text-field :disabled="loading" v-model="password" :rules="password_rules"
                            :type="show_pass1 ? 'text' : 'password'" :append-icon="show_pass1 ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
									"  counter outlined hint="Must be at least 8 characters long" persistent-hint
                           @click:append="show_pass1 = !show_pass1"></v-text-field>

              <label>Confirm Password*</label>

              <v-text-field :disabled="loading" v-model="password2" :rules="password_rules"
                            :type="show_pass2 ? 'text' : 'password'" counter outlined
                            :append-icon="show_pass2 ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
									" @click:append="show_pass2 = !show_pass2"></v-text-field>

            </v-form>
            <v-alert type="error" v-if="login_error">
              <h5>{{ error_msg }}</h5>
            </v-alert>

            <v-row>
              <v-col cols="1" sm="1" class="">
                <v-checkbox :disabled="loading" class="p-0 m-0" color="blue darken-4" v-model="read_terms"></v-checkbox>
              </v-col>

              <v-col cols="11" sm="11">
                Agree to Built Accounting's
                <a href="https://builtaccounting.com/terms-conditions/" target="_blank">Terms</a>
                and
                <a target="_blank" href=" https://builtaccounting.com/terms-conditions/">Conditions</a>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" sm="6">
                <v-btn :disabled="loading" color="blue" @click="step = step - 1" text>
                  <v-icon>mdi-arrow-left</v-icon>
                  Back
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>

          <v-stepper-content step="3">
            <center>
              <h3 class="font-weight-light">
                Verification code was sent to
                <strong>{{ phone_number }}</strong>
              </h3>
              <h4>Enter the code to verify your phone number</h4>
            </center>
            <v-btn text v-if="!progress" :disabled="loading" @click="step = 2">
              <v-icon>mdi-arrow-left</v-icon>
              Back
            </v-btn>
            <v-btn color="blue" text :loading="progress" @click="SendOTP">Resend code
            </v-btn>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card-text>
    <v-card-actions class="d-flex flex-column align-center justify-center">
      <v-btn v-if="step === 2"
             :loading="loading"
             rounded
             depressed
             block
             :disabled="!read_terms"
             x-large
             color="green"
             @click.prevent="signup"
             class="text-capitalize white--text font-weight-bold mx-auto">Signup
      </v-btn>
    </v-card-actions>
    <div class="text-center">Already have a CFO AI account?
      <v-btn small text color="blue lighten-1" class="text-capitalize" @click="$router.push('/auth/login')">Log in
      </v-btn>
    </div>
  </div>
</template>
<script>
import PhoneNumberInput from "@/components/PhoneNumberInput.vue";
import { setAccessToken } from "@/utils";

export default {
  name: "SignupComponent",
  components: {PhoneNumberInput},
  data() {
    return {
      email: '',
      password: '',
      password2: '',
      show_pass: false,
      show_pass2: false,
      loading: false,
      step: 1,
      gender: null,
      first_name: "",
      last_name: "",
      call_code: "",
      flag: "",
      signupForm: "",
      phone_number: "",
      new_password: "",
      confirm_password: "",
      new_email: "",
      progress: false,
      show_pass1: false,
      remember_me: true,
      valid_form: false,
      login_error: false,
      subscription_package: "",
      error_msg: "",
      countries: null,
      amount: 0,
      currency: "",
      country: "",
      is_service: false,
      is_retail_wholesale: true,
      preview: "",
      password_rules: [
        (value) => !!value || "Password is Required.",
        (value) =>
            value.length >= 8 || "Password must be at least 8 characters",
      ],
      first_name_rules: [(value) => !!value || "Name is Required."],
      country_rules: [(value) => !!value || "Country is Required."],
      phone_number_rules: [(value) => !!value || "Phone number is Required."],
      email_rules: [(value) => !!value || "E-mail is Required."],
      account_infor: false,
      personal_infor: false,
      items: [
        {gender: "Male", value: "male"},
        {gender: "Female", value: "female"},
        {gender: "Prefer not to say", value: "other"},
      ],

      read_terms: false,
      exist: false,
      checking: false,
    }
  },
  methods: {
    signup(e) {

      if (this.$refs.account_form.validate()) {
        if (!this.read_terms) {
          this.error_msg = "You have not agreed to our terms and conditions";
          this.login_error = true;
          this.progress = false;
          return false;
        }

        if (this.password !== this.password2) {
          this.login_error = true;
          this.error_msg =
              "Your passwords are not the same please check and try again";
          return;
        }

        this.loading = true
        axios
            .post("/api/checkemail", {email: this.new_email})
            .then((res) => {
              this.checking = false;
              this.exist = res.data.exist;
              if (this.exist) {

                return;
              }

              let signup_form = new FormData();
              signup_form.append("first_name", this.first_name);
              signup_form.append("last_name", this.last_name);
              signup_form.append("gender", this.gender);
              signup_form.append("phone_number", this.phone_number);
              signup_form.append("email", this.email);
              signup_form.append("media", "CFOAI");
              signup_form.append("password", this.password);
              signup_form.append("ticket", null);
              signup_form.append(
                  "password_confirmation",
                  this.password2
              );

              axios
                  .post("/api/auth/v2/signup", signup_form)
                  .then((res) => {
                    if (res.status === 302) {
                      this.exist = true;
                      return false;
                    }
                    setAccessToken(res.data.access_token);
                    // this.$router.push({ path: "/dashboard" });
                    window.location = "/dashboard";
                    // location.reload();
                  })
                  .catch(() => {
                    this.loading = false;
                  })
            });
        // this.loading = false

      }
    },
    goto_account() {
      if (this.$refs.person_form.validate()) {
        this.step = 2;
      }
    },
    SendOTP() {
      if (!this.read_terms) {
        this.error_msg = "You have not agreed to our terms and conditions";
        this.login_error = true;
        this.progress = false;
        return false;
      }

      if (this.password !== this.password2) {
        this.error_msg = "Passwords do not match!";
        this.login_error = true;
        this.progress = false;
        return false;
      }

      this.progress = true;
      axios
          .get("/api/auth/code/" + this.phone_number)
          .then((res) => {
            this.otp_id = res.data.id;
            this.step = 3;
            this.progress = false;
          })
          .catch((error) => {
            this.progress = false;
            this.error_msg =
                error.response.status === 302
                    ? error.response.data
                    : "Something went wrong, could not process your signup";
            this.login_error = true;
          });
    },
    check_exist() {
      if (this.email) {
        this.checking = true;

        axios.post(`/api/checkemail`, {email: this.email}).then((res) => {
          this.checking = false;
          this.exist = res.data.exist;
        });
      }
    },

  }
}
</script>

<style scoped></style>