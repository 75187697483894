import { render, staticRenderFns } from "./GeneralErrorComponent.vue?vue&type=template&id=0ecb759b&scoped=true"
import script from "./GeneralErrorComponent.vue?vue&type=script&lang=js"
export * from "./GeneralErrorComponent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ecb759b",
  null
  
)

export default component.exports