/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
import Vue from "vue";
import router from "./router";
import Vuetify from "vuetify";
import VueRouter from "vue-router";
import "vuetify/dist/vuetify.min.css";
import Cookies from "js-cookie";

import "@mdi/font/css/materialdesignicons.css";
import moment from "moment";
import VueApexCharts from "vue-apexcharts";
import store from "./store";
import App from "./App.vue";
import "./registerServiceWorker";
import ElementUI from "element-ui";
import { ElementTiptapPlugin } from "element-tiptap";
// import ElementUI's styles
import "element-ui/lib/theme-chalk/index.css";
// import this package's styles
import "element-tiptap/lib/index.css";
import VueSocketIO from "vue-socket.io";
import SocketIO from "socket.io-client";
import VueIntercom from "vue-intercom";

// use ElementUI's plugin
Vue.use(ElementUI);
Vue.use(VueIntercom, {appId: "ev5nda20"});
// use this package's plugin
Vue.use(ElementTiptapPlugin, {
  /* plugin options */
});

Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);

require("./bootstrap");
let prodURL = "https://soc-net.builtaccounting.com/";
Vue.use(
	new VueSocketIO({
		connection: SocketIO(prodURL),
		vuex: {
			store,
			actionPrefix: "SOCKET_",
			mutationPrefix: "SOCKET_",
		},

	})
);

Vue.use(Vuetify);
Vue.use(VueRouter);

Vue.prototype.moment = moment;
Vue.config.productionTip = false;
Vuetify.config.silent = true;

new Vue({
  router,
  store,
  moment,
  vuetify: new Vuetify(),
  render: (h) => h(App),
  created() {
    let params = new URL(document.location.toString()).searchParams;
    let token = params.get("s");
    if (token) {
      Cookies.set("access_token", token);
      window.location = "/";
    }
  },
}).$mount("#app");

Vue.filter("humanDate", (d) => {
	if (d) {
		return moment(d, "YYYY-MM-DD").format("MMM Do, YYYY");
	}
});

Vue.filter("humanDateTime", (d) => {
	if (d) {
		return moment(d).format("MMM Do YYYY, h:mm A");
	}
});