import Vue from "vue";
import Vuex from "vuex";
import {getAccessToken} from "@/utils";
import {init as initCommandBar} from 'commandbar';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		appLogos: {
			quickbooks: "https://built.africa/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fquickbooks-logo.ff45b94b.png&w=256&q=75",
			xero: "https://built.africa/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fxero-logo.a6654be2.png&w=256&q=75",
			freshbooks: "https://built.africa/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Ffreshbooks-logo.dd1e73f2.png&w=256&q=75",
			freeagent: "https://built.africa/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Ffreeagent-logo.f1c238c3.png&w=256&q=75",
			"built-accounting": "https://built-storage.ams3.cdn.digitaloceanspaces.com/Built/icon.png"
		},
		stats: {
			count: 0
		},
		currencies: [],
		reports: [],
		user: null,
		managementProfile: null,
		initializing: false,
		isLoggedIn: false,
		currentReport: null,
		sidebarOpen: true,
		profileExist: false,
		email_rules: [
			(v) => !!v || "E-mail is required",
			(v) => /.+@.+/.test(v) || "E-mail must be valid",
		],
		requiredRule: [(v) => !!v || "This field is required"],
		showGeneralMessage: false,
		generalMessage: '',
		baseURL: "https://web.built.africa",
		apideckSessionToken: null,
		fromExternalApp: false,
		service_id: '',
		hasProfile: true,
		consumer_id: '',
	},
	getters: {
		getExistingFile: (state) => (name) => {
			return state.reports.find((one) => {
				return one.fileName === name;
			});
		},
		getUniqueCategories(state) {
			const categories = state.reports.map((item) => item.category);
			return [...new Set(categories)].sort();
		},
		getRecentDocuments(state) {
			return state.reports.slice(0, 6);
		},
	},
	mutations: {

		INIT_USER(state) {
			state.initializing = true;

			axios.get("/api/user")
				.then(res => {

					state.user = res.data;

					if (!state.user.email_verified_at) {

						state.initializing = false;
						state.hasProfile = false;

					} else {

						axios.get('/api/management-reports/settings')
							.then((res) => {
								state.initializing = false;
								state.managementProfile = res.data;

								initCommandBar('02d6f771');


								const userID = state.user.id + "-" + state.managementProfile.id;

								window.CommandBar.boot(userID);


							})
							.catch((err) => {

								// If the user doesn't have a management profile, create one
								if (err.response.status === 302) {


									state.hasProfile = false;


								}

								state.initializing = false;

							});
					}

				})
				.catch(() => {
					state.initializing = false;

				})


		},

		TOGGLE_LOGIN(state, payload) {
			state.isLoggedIn = payload
		},
		SET_EXTERNAL(state, payload) {
			state.fromExternalApp = payload
		},
		SET_SERVICE(state, payload) {
			state.service_id = payload
		},
		SET_CUSTOMER(state, payload) {
			state.consumer_id = payload
		},

		TOGGLE_SIDEBAR(state, payload) {
			state.sidebarOpen = payload
		},
		SET_USER(state, payload) {
			state.user = payload;
		},
		SET_PROFILE(state, payload) {
			state.managementProfile = payload;
		},
		SET_REPORTS(state, payload) {
			state.reports = payload;
		},
		SET_CURRENT_REPORT(state, payload) {
			state.currentReport = payload;
		}

	},
	actions: {
		setProfile({commit}, profile) {
			commit('SET_PROFILE', profile)
		},
		setExternal({commit}, payload) {
			commit('SET_EXTERNAL', payload)
		},
		setService({commit}, payload) {
			commit('SET_SERVICE', payload)
		},
		setCustomer({commit}, payload) {
			commit('SET_CUSTOMER', payload)
		},

		generateReport({commit}, payload) {
			let url = "/api/management-reports";
			let token = getAccessToken();
			axios
				.post(url, payload, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				})
				.then((resp) => {
					console.log(resp);
				});
		},
		async getReports({commit, state}) {
			let api = "/api/management-reports";
			let token = getAccessToken();

			let resp = await axios.get(api, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			// console.log(resp.data.data);
			commit("SET_REPORTS", resp.data.data);
		},
		initializeUser({commit, dispatch}) {
			return dispatch('getReports')
				.catch(error => {
					// Handle errors appropriately, e.g., show a notification
					console.error('Error initializing user:', error)
				})
		},
	},
});