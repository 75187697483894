<template>
  <v-container>

    <v-row dense v-if="$store.state.user.email_verified_at">
      <v-col cols="12" sm="6" class="mx-auto">
        <v-card flat>
          <v-card-text class="text-center">
            <center>

              <v-img width="250" src="/img/undraw_informed_decision_p2lh.svg" contain class="ma-6"></v-img>
              <h3 class="display-2 font-weight-light">Welcome to CFO <span class="blue--text font-weight-black">AI<sup>
            <v-icon color="yellow" class="ma-0" style="left:-3px"
                    small>mdi-shimmer</v-icon></sup></span></h3>

              <h2 class="mt-4 mb-4 green--text">Get  <div class="font-weight-black text-h3 green--text green lighten-5 pt-3" style="width: 70px; height: 70px; border-radius: 50%;">1</div>  Report for free!, get started now</h2>

              <p class="font-weight-light">Get professional management reports and analytics from your transactions with just a click!</p>
              <v-btn @click="createProfile" :loading="loading" color="green" x-large depressed rounded dark>Get
                Started
              </v-btn>
              <v-btn color="red" @click="showLogoutDialog=true" block text x-large class="d-block mt-4" rounded>Log
                Out
              </v-btn>
            </center>

          </v-card-text>
        </v-card>
      </v-col>
    </v-row>


    <v-row dense v-else>
      <v-col cols="12" sm="6" class="mx-auto">
        <v-card flat>
          <v-card-text class="text-center">
            <center>

              <v-img width="250" src="/img/email.svg" contain class="ma-6"></v-img>
              <h3 class="display-2 font-weight-light">Welcome to CFO <span class="blue--text font-weight-black">AI<sup>
            <v-icon color="yellow" class="ma-0" style="left:-3px"
                    small>mdi-shimmer</v-icon></sup></span></h3>
              <p class="text-h5 font-weight-light">Verify your account to continue</p>
              <p>We have sent a verification link to your email <strong>({{ $store.state.user.email }})</strong></p>
              <p>Did not get the email?
                <v-btn text color="blue" :loading="resending" @click="resendVerification">Resend email</v-btn>
              </p>
              <v-btn color="red" @click="showLogoutDialog=true" block text x-large class="d-block mt-4" rounded>Log
                Out
              </v-btn>
            </center>

          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
        width="500"
        v-model="showLogoutDialog"
    >
      <v-card>
        <v-card-title>Confirm logout</v-card-title>
        <v-divider class="mb-5"></v-divider>
        <v-card-text>
          <h3 class="red--text">Do you want to logout?</h3>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="logOut" :loading="loggingOut" block color="red" dark x-large rounded depressed
                 class="text-capitalize">Yes, Log me out
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="showMessage">{{ message }}</v-snackbar>
  </v-container>

</template>

<script>
import {defineComponent} from 'vue'
import {removeToken} from "@/utils";

export default defineComponent({
  name: "GetStartedComponent",
  data() {
    return {
      loading: false,
      showLogoutDialog: false,
      loggingOut: false,
      resending: false,
      message: "",
      showMessage: false

    }
  },
  methods: {
    resendVerification() {
      this.resending = true;
      axios.get("/api/sendverify")
          .then(res => {
            this.resending = false;
            this.message = "We just shot your another email, checkout your inbox for the verification link.";
            this.showMessage = true;
          })
    },
    logOut() {
      this.loggingOut = true;
      axios.get("/api/auth/logout")
          .then(res => {
            removeToken()
            this.$router.push('/auth/login')
            this.loggingOut = false;
            window.location.reload()
          })

    },
    createProfile() {
      this.loading = true;
      axios.post("/api/management-reports/settings")
          .then(res => {
            this.loading = false;

            location.reload();
          })


    }
  }
})
</script>

<style scoped>

</style>