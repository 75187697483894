<template>
  <div>
    <h4 class="text-center text-h6">Login with your
      <a class="text-decoration-none"
         style="font-family: inherit !important;"
         href="https://built.africa"
         target="_blank"
      >Built<sup>
        <v-icon color="blue" x-small>mdi-arrow-right-top</v-icon>
      </sup></a> Account
    </h4>
    <v-card-text class="mt-4">
      <v-form ref="login_form">
        <label>Email</label>
        <v-text-field type="email" outlined v-model="email" autofocus
                      :rules="[(v) => !!v || 'E-mail is required']" flat placeholder="Built account email"
                      @keydown.enter="login"></v-text-field>

        <label>Password</label>

        <v-text-field @keydown.enter="login" outlined :rules="[(v) => !!v || 'Password is required']"
                      v-model="password" placeholder="Built account password" flat
                      :type="show_pass ? 'text' : 'password'"
                      @click:append="show_pass = !show_pass" :append-icon="show_pass ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
						"></v-text-field>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn :loading="loading" rounded depressed block x-large color="blue darken-4" @click.prevent="login"
             class="text-capitalize white--text font-weight-bold mx-auto mb-5">Login
      </v-btn>

    </v-card-actions>
    <div class="text-center">Don't have a CFO AI account?
      <v-btn small text color="blue lighten-1" class="text-capitalize"
             @click="$router.push('/auth/signup')">Create account
      </v-btn>
    </div>
  </div>
</template>
<script>
import { setAccessToken } from "@/utils";

export default {
  name: "LoginComponent",
  data() {
    return {
      email: null,
      password: null,
      snackbar: false,
      snackbarMsg: null,
      snackbarColor: null,
      loading: false,
      showPassword: false,
      show_pass: false
    };
  },

  methods: {
    login() {
      if (this.$refs.login_form.validate()) {
        this.loading = true;
        const formdata = {
          email: this.email,
          password: this.password,
        };

        axios.post("/api/auth/login", formdata)
            .then((response) => {
              const user = response.data.user;

              const accessToken = response.data.access_token;


              setAccessToken(accessToken);
              // this.getMgmtProfile(accessToken)
            this.$store.commit("SET_USER", user);

              // this.$router.push({ path: "/" });
              window.location = "/"

            })
            .catch((error) => {
              console.error("Login error:", error);
              this.snackbar = true;
              this.snackbarMsg = "Something went wrong. Please try again later.";
              this.snackbarColor = "error";
              this.loading = false;
            });
      }
    },

  },
  computed: {

    passwordType() {

      return this.showPassword ? "text" : "password";

    },

    passwordIcon() {

      return this.showPassword ? "mdi-eye-off-outline" : "mdi-eye-outline"

    },

  },
}
</script>

<style></style>